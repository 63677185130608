import { ref } from 'vue'
import { set } from '@vueuse/core'

export const useLogout: () => Ref<string | null> = () => {
  const bugsnag = useBugsnag()
  const logout = ref(null)
  const oryInstance: any = useOry()

  oryInstance.createBrowserLogoutFlow()
    .then(({ data }: any) => {
      set(logout, data.logout_url)
    })
    .catch((error: any) => {
      bugsnag.notify(error)
      set(logout, '#')
    })

  return logout
}
