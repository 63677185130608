<script setup lang="ts">

const { isHelpSlideoverOpen } = useDashboard()
const { isDashboardSearchModalOpen } = useUIState()
const { metaSymbol } = useShortcuts()
const logoutUrl = useLogout()
const { setFlowId, setKratosFlow } = useFlowOry()

const handleLogout = () => {
  setFlowId(null)
  setKratosFlow(null)
  return navigateTo(logoutUrl.value, { external: true })
}

const items = computed(() => [
  [{
    slot: 'account',
    label: '',
    disabled: true
  }], [{
    label: 'Configuración',
    icon: 'i-heroicons-cog-8-tooth',
    to: '/configuration',
    shortcuts: ['G', 'C'],
  }, {
    label: 'Command menu',
    icon: 'i-heroicons-command-line',
    shortcuts: [metaSymbol.value, 'K'],
    click: () => {
      isDashboardSearchModalOpen.value = true
    }
  }, {
    label: 'Help & Support',
    icon: 'i-heroicons-question-mark-circle',
    shortcuts: ['?'],
    click: () => isHelpSlideoverOpen.value = true
  }], [{
    label: 'Documentation',
    icon: 'i-heroicons-book-open',
    to: 'https://ui.nuxt.com/pro/getting-started',
    target: '_blank'
  }, {
    label: 'GitHub repository',
    icon: 'i-simple-icons-github',
    to: 'https://github.com/nuxt-ui-pro/dashboard',
    target: '_blank'
  }, {
    label: 'Buy Nuxt UI Pro',
    icon: 'i-heroicons-credit-card',
    to: 'https://ui.nuxt.com/pro/purchase',
    target: '_blank'
  }], [{
    label: 'Cerrar sesión',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: handleLogout
  }]
])

const { userAuthOryData } = useUserAuthOry()

const userName = computed(() => userAuthOryData.value?.identity?.traits?.name ?? 'Usuario')
const userEmail = computed(() => userAuthOryData.value?.identity?.traits?.email ?? 'user@test.com')


</script>

<template>
  <UDropdown mode="hover" :items="items" :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }" :popper="{ strategy: 'absolute', placement: 'top' }" class="w-full">
    <template #default="{ open }">
      <UButton color="gray" variant="ghost" class="w-full" :label="userName" :class="[open && 'bg-gray-50 dark:bg-gray-800']">
        <template #leading>
          <UIcon name="i-heroicons-user" class="w-4 h-4 text-primary dark:text-secondary" />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          Iniciaste sesión con
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ userEmail }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
